
const mutations={
  updateLoadnum(state,value){state.loadnum=value},//加载次数
  updateShowLoading(state,value){state.isLoading = value},//是否加载中
  updateUserStatus(state,value){state.userStatus=value},//更新用户状态
  updateStoreList(state,value){state.boss.storeList=value},//缓存门店列表
  updateBossPower(state,value){state.userStatus.boss_power=value},//缓存菜单权限
  updateCursNodeId2(state,value){state.userStatus.curs_node_id_2=value},//缓存门店id
  updateIsShowTabBar(state,value){state.boss.isShowTabBar = value},//修改是否展示底部导航状态
  updateIsShowPickStore(state,value){state.boss.isShowPickStore = value},//修改是否展示浮动圆形导航状态
  updateRevenueChart(state,value){state.boss.revenueChart = value},//修改走势图
  updateAnalysisChart(state,value){state.boss.analysisChart=value},//修改分析七日走势图
  updateAllowPull(state,value){state.boss.allowPull = value},//是否允许下拉
  updateTechDetail(state,value){state.boss.techDetail = value},//更新技师详情
  updateShowTechDetail(state,value){state.boss.showTechDetail = value},//是否显示技师详情
  updateTechRankingType(state,value){state.boss.techRankingType = value},//修改技师排名类型
  updateRenderBarList(state,value){state.boss.renderBarList=value}//修改tabbar菜单
}
export default mutations
