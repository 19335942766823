let util={}
/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = (titleText) =>{
	document.title = titleText
}
/**
 * 判断数据类型
 * @param n
 * @returns {string}
 */
util.varType = (n) =>{
	let typeStr = Object.prototype.toString.call(n)
	let typeName = ''
	switch(typeStr){
		case '[object String]':
			typeName = 'string'
			break
		case '[object Number]':
			typeName = 'number'
			break
		case '[object Boolean]':
			typeName = 'boolean'
			break
		case '[object Undefined]':
			typeName = 'undefined'
			break
		case '[object Object]':
			typeName = 'object'
			break
		case '[object Array]':
			typeName = 'array'
			break
		case '[object Null]':
			typeName = 'null'
			break
		case '[object RegExp]':
			typeName = 'RegExp'
			break
		case '[object Symbol]':
			typeName = 'symbol'
			break
		case '[object JSON]':
			typeName = 'json'
			break
		case '[object Math]':
			typeName = 'math'
			break
		case '[object Date]':
			typeName = 'Date'
			break
		default:
			typeName = 'object'
	}
	return typeName
}

/**
 * 日期格式化
 * @param n
 * @returns {string}
 */
util.formatDate = (date,fmt) =>{
	fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
	if(util.varType(date) !== 'Date'){
		if(util.varType(date) === 'string'){
			date = date.replace(/-/g,'/')
		}
		date = new Date(date)
	}
	let padLeftZero = (str) =>{
		return ('00' + str).substr(str.length)
	}
	if(/(y+)/.test(fmt)){
		fmt = fmt.replace(RegExp.$1,(date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+':date.getMonth() + 1,
		'd+':date.getDate(),
		'h+':date.getHours(),
		'm+':date.getMinutes(),
		's+':date.getSeconds()
	}
	for(let k in o){
		if(new RegExp(`(${k})`).test(fmt)){
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1,RegExp.$1.length === 1?str:padLeftZero(str))
		}
	}
	return fmt
}

/**
 * 获取某月或者当月  第一天与最后一天
 */
util.getMonthEndStartDay = (month) =>{
	if(! ! month){
		month = month - 1;
	}else{
		month = new Date().getMonth;
	}
	var nowDate = new Date(new Date().setMonth(month));
	var cloneNowDate = new Date(new Date().setMonth(month,1));
	var fullYear = nowDate.getFullYear();
	var endOfMonth = new Date(fullYear,(month + 1),0).getDate(); // 获取本月最后一天
	var endDate = util.formatDate(cloneNowDate.setDate(endOfMonth),'yyyy-MM-dd');
	var starDate = util.formatDate(cloneNowDate.setDate(1),'yyyy-MM-dd');
	return {
		starDate,
		endDate
	}
}

/**
 * 获取时间参数
 */
util.getTimeParam = (type,month) =>{
	let params = {
		type
	}
	let oneDay = 24 * 60 * 60 * 1000
	let today = util.formatDate(new Date(),'yyyy-MM-dd')
	let yesterday = util.formatDate((new Date().getTime() - oneDay),'yyyy-MM-dd')
	let recentSeven = util.formatDate((new Date().getTime() - 7 * oneDay),'yyyy-MM-dd')
	let firstDay = util.formatDate(new Date().setDate(1),'yyyy-MM-dd')
	switch(type){
		case 1:
			params.startTime = today
			params.endTime = today
			break
		case 2:
			params.startTime = yesterday
			params.endTime = yesterday
			break
		case 3:
			params.startTime = recentSeven
			params.endTime = yesterday
			break
		case 4:
			params.startTime = firstDay
			params.endTime = today
			break
		default:
			params.startTime = util.getMonthEndStartDay(month).starDate
			params.endTime = util.getMonthEndStartDay(month).endDate
			break
	}
	return params
}

/*深拷贝*/
util.deepClone = (source) =>{
	if(! source || typeof source !== 'object'){
		throw new Error('error arguments','shallowClone');
	}
	var targetObj = source.constructor === Array?[]:{};
	for(var keys in source){
		if(source.hasOwnProperty(keys)){
			if(source[keys] && typeof source[keys] === 'object'){
				targetObj[keys] = source[keys].constructor === Array?[]:{};
				targetObj[keys] = util.deepClone(source[keys]);
			}else{
				targetObj[keys] = source[keys];
			}
		}
	}
	return targetObj;
}

/**
 * @description:函数防抖  连续多次请求只会发出一次请求
 * @author: dream breeze
 * @date: 2019-07-24 16:59
 */
util.debounce = (fn,delay) =>{
	let timer = null
	delay = delay || 500
	return function(){
		let context = this
		let arg = arguments
		clearTimeout(timer)
		timer = setTimeout(function(){
			timer = null
			fn.apply(context,arg)
		},delay)
	}
}

/*
TabBar菜单权限筛选
*/
util.navAuthority=(navTabBar,boss_power)=>{
  let renderBarList = util.deepClone(navTabBar)
  return renderBarList.filter(function(item,index){
    if(item[`menu_${index+1}`]===boss_power[`menu_${index+1}`]){return item}
  })
}

// 返回天数差
util.getRestDay=(time)=>{
  var now=Date.now()
  time=new Date(time)
  // var restTime=time-now-8*1000*60*60
  var restTime=time-(now-24*1000*60*60)
  if(restTime<0)return 0
  return Math.floor(restTime/(1000*60*60*24))
}

export default util
