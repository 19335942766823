const routes = [
  {
    path: '/',
    redirect: '/store-message'
  },
  {
    path: '/store-message',
    name: 'store-message',
    component: () => import('@/views/store-message/store-message')
  },
  {
    path: '/revenue',
    name: 'revenue',
    meta:{
      title:'千里眼营业报表'
    },
    component: () => import('@/views/revenue/revenue')
  },
  {
    path: '/analysis',
    name: 'analysis',
    meta:{
      title:'分析'
    },
    component: () => import('@/views/analysis/analysis')
  },
  {
    path: '/room',
    name: 'room',
    meta:{
      title:'房间'
    },
    component: () => import('@/views/room/room')
  },
  {
    path: '/technician',
    name: 'technician',
    meta:{
      title:'技师'
    },
    component: () => import('@/views/technician/technician')
  }
]

export default routes
