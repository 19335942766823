const state={
	boss:{
		storeList:[],// 门店列表
    isShowTabBar: false,//是否展示底部导航
    isShowPickStore: false,//是否展示选择门导航
    revenueChart:[],// 营业走势图
    anaylsisList:[{link:'projects-analysis',label:'项目分析'},{link:'revenue-analysis',label:'营业分析'},{link:'member-analysis',label:'会员分析'}],
    analysisChart:[],// 分析七日走势图
    techHeader:[{link:'technician-state',label:'技师状态'},{link:'technician-analysis',label:'技师分析'},{link:'technician-ranking',label:'技师排名'}] ,//技师头部导航
    allowPull:true,//允许下拉
    techDetail:null,//技师详情
    showTechDetail:false,//技师详情显隐标识
    techRankingType:1,//技师排名类型 （点钟 or 总钟）
    // techRankingType: 'total_clock'//技师排名类型 （点钟 or 总钟）
    //导航数据tabBar
    navTabBar: [{
      "menu_1":1,
    	"id": 11,
    	"name": "营收",
    	"imageUrl": require('@/assets/images/revenue-checked-icon.png'),
    	"uncheckImgUrl": require('@/assets/images/revenue-icon.png'),
    	"link": "revenue"
    }, {
      "menu_2":1,
    	"id": 15,
    	"name": "分析",
    	"imageUrl": require('@/assets/images/analysis-checked-icon.png'),
    	"uncheckImgUrl": require('@/assets/images/analysis-icon.png'),
    	"link": "analysis",
    }, {
      "menu_3":1,
    	"id": 16,
    	"name": "房态",
    	"imageUrl": require('@/assets/images/room-checked-icon.png'),
    	"uncheckImgUrl": require('@/assets/images/room-icon.png'),
    	"link": "room"
    }, {
      "menu_4":1,
    	"id": 17,
    	"name": "技师",
      "imageUrl": require('@/assets/images/technician-checked-icon.png'),
    	"uncheckImgUrl": require('@/assets/images/technician-icon.png'),
    	"link": "technician"
    }],
    renderBarList:[]//筛选后的导航数据tabBar
	}
}
export default state
