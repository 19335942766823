let exports = {
	valueKbit:(value) => {
    let result="";
    if(!value || value == '.00'||value == '0.00'){
      result = "0.00"
    }else{
      value = parseFloat(value)
      value =value.toFixed(2)
      result=(value || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
    return result
	}
}
export default exports