<template>
  <div class="bossApp" ref="bossApp">
    <!-- 内容容器 -->
    <div class="app-container">
      <router-view/>
    </div>
    <!-- tabBar菜单 -->
    <tab-bar v-show="boss.isShowTabBar"></tab-bar>
    <!-- 切换门店按钮 -->
    <div @click="isShowStoreList=true">
        <div class="pick-btn"
             v-if="boss.isShowPickStore"
             ref="pickRef">
             <img src="@/assets/images/cutButton.png">
             <span>切换</span>
        </div>
    </div>
    <div @click="home" style="z-index: 2">
        <div class="home"
             v-if="boss.isShowPickStore"
             >
             <img src="@/assets/images/homeButton.png">
             <span>首页</span>
        </div>
    </div>
    <loading v-if="isLoading"></loading>
    <!-- 切换门店组件 -->
    <pick-store :isShowStoreList="isShowStoreList" @choosePickItem="isShowStoreList=false" @closeWindow="isShowStoreList=false"></pick-store>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'boss',
  data () {
    return {
      isShowStoreList:false
    }
  },
  components:{
    'tab-bar':() => import('@/components/tab-bar.vue'),
    'pick-store':() => import('@/components/pick-store'),
    'loading':() => import('@/components/loading.vue')
  },
  computed:{
    ...mapState(['boss','isLoading'])
  },
  methods:{
    home(){
      location.href=location.protocol+'//'+location.host
    }
  }
}
</script>

<style lang="scss">
  .bossApp{
      height:100vh;
      display:flex;
      flex-direction:column;
      position:relative;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      /*应用主体*/
      .app-container{
          flex:1;
          max-height:100vh;
          // line-height:1.5;
          background:#EEEEEE;
          overflow-x:hidden;
          overflow-y:auto;
          -webkit-overflow-scrolling:touch;
          overflow-scrolling:touch;
          position:relative;
      }
      // 切换门店
      .pick-btn{
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
          position:fixed;
          right: 20px;
          bottom: 400px;
          width:72px;
          height:72px;
          background:#3395F6;
          text-align:center;
          font-style:normal;
          color:#fff;
          font-size:26px;
          line-height:1;
          z-index:5;
          border-radius:50%;
          padding: 16px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img{width: 27px;height: 27px;margin-bottom: 6px;}
          span{font-size: 18px;}
      }
      .home{
        position:fixed;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
        right: 20px;
        bottom: 300px;
        width:72px;
        height:72px;
        border-radius:50%;
        background: #FF7C4A;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        img{width: 27px;height: 27px}
        span{font-size: 18px;}
      }
  }
</style>
